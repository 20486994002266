import React, { useState } from "react";
import { Container } from "@epcnetwork/core-ui-kit";
import { useCache, useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import cn from "classnames";

import { SuppressionsStatus } from "./suppressions-status/suppressions-status";
import { steps } from "./dashboard.tour";
import { InfoBox } from "pages/dashboard/info-box/info-box";
import { getActiveEsps, getDashboardGeneral } from "api";
import { DashboardGeneralDataState } from "api/dashboard/dashboard.types";
import { DashboardGeneralData, DashboardListenerEventsKeys, useDashboardSocket, useTour } from "hooks";

import styles from "./dashboard.module.scss";

export const DashboardPage: React.FC = () => {
  useTour({
    name: "dashboard",
    steps,
  });

  const { socket } = useDashboardSocket();

  const [generalData, setGeneralData] = useState<DashboardGeneralDataState | null>(null);

  const { data: activeEsps, loading: espsLoading } = useFetch(getActiveEsps);

  const { loading, onSuccess } = useFetch(getDashboardGeneral);
  onSuccess(({ response }) => {
    const iterable: Record<string, number> = {};
    const salesforce: Record<string, number> = {};
    const remarkety: Record<string, number> = {};
    const marketo: Record<string, number> = {};
    const greenarrow: Record<string, number> = {};
    const insider: Record<string, number> = {};
    const blueshift: Record<string, number> = {};

    Object.entries(response.iterable).forEach(([key, value]) => (iterable[key] = +value));
    Object.entries(response.salesforce).forEach(([key, value]) => (salesforce[key] = +value));
    Object.entries(response.remarkety).forEach(([key, value]) => (remarkety[key] = +value));
    Object.entries(response.marketo).forEach(([key, value]) => (marketo[key] = +value));
    Object.entries(response.greenarrow).forEach(([key, value]) => (greenarrow[key] = +value));
    Object.entries(response.insider).forEach(([key, value]) => (insider[key] = +value));
    Object.entries(response.blueshift).forEach(([key, value]) => (blueshift[key] = +value));

    setGeneralData({
      iterable,
      salesforce,
      remarkety,
      marketo,
      greenarrow,
      insider,
      blueshift,
    } as DashboardGeneralDataState);
  });

  useDidUpdate(
    () => {
      const handleGeneralDataUpdate = ({ type, oldState, newState }: DashboardGeneralData) => {
        setGeneralData((previousData) => {
          if (previousData) {
            const updatedState = oldState
              ? {
                  ...previousData[type],
                  [oldState]:
                    +previousData[type][oldState] > 0
                      ? +previousData[type][oldState] - 1
                      : previousData[type][oldState],
                  [newState]: previousData[type][newState] + 1,
                }
              : { ...previousData[type], [newState]: previousData[type][newState] + 1 };

            return { ...previousData, [type]: updatedState };
          }

          return previousData;
        });
      };

      if (socket) {
        socket.on<DashboardListenerEventsKeys>("dashboardGeneralUpdate", handleGeneralDataUpdate);

        return () => {
          socket.off<DashboardListenerEventsKeys>("dashboardGeneralUpdate", handleGeneralDataUpdate);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <>
      <Container contentClassName={styles.container}>
        <div className={cn(styles.boxContainer, "dashboard-general")}>
          <InfoBox
            integration="iterable"
            completed={generalData ? generalData.iterable.completed : "-"}
            failed={generalData ? generalData.iterable.failed : "-"}
            active={generalData ? generalData.iterable.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.iterable || false}
            espsLoading={espsLoading}
          />
          <InfoBox
            integration="salesforce"
            completed={generalData ? generalData.salesforce.completed : "-"}
            failed={generalData ? generalData.salesforce.failed : "-"}
            active={generalData ? generalData.salesforce.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.salesforce || false}
            espsLoading={espsLoading}
          />
          <InfoBox
            integration="remarkety"
            completed={generalData ? generalData.remarkety.completed : "-"}
            failed={generalData ? generalData.remarkety.failed : "-"}
            active={generalData ? generalData.remarkety.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.remarkety || false}
            espsLoading={espsLoading}
          />
          <InfoBox
            integration="marketo"
            completed={generalData ? generalData.marketo.completed : "-"}
            failed={generalData ? generalData.marketo.failed : "-"}
            active={generalData ? generalData.marketo.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.marketo || false}
            espsLoading={espsLoading}
          />
          <InfoBox
            integration="greenarrow"
            completed={generalData ? generalData.greenarrow.completed : "-"}
            failed={generalData ? generalData.greenarrow.failed : "-"}
            active={generalData ? generalData.greenarrow.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.greenarrow || false}
            espsLoading={espsLoading}
          />
          <InfoBox
            integration="insider"
            completed={generalData ? generalData.insider.completed : "-"}
            failed={generalData ? generalData.insider.failed : "-"}
            active={generalData ? generalData.insider.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.insider || false}
            espsLoading={espsLoading}
          />
          <InfoBox
            integration="blueshift"
            completed={generalData ? generalData.blueshift.completed : "-"}
            failed={generalData ? generalData.blueshift.failed : "-"}
            active={generalData ? generalData.blueshift.active : "-"}
            description="in the last 24 hours"
            loading={loading}
            isEspActive={activeEsps?.blueshift || false}
            espsLoading={espsLoading}
          />
        </div>
        <div className={styles.content}>
          <SuppressionsStatus socket={socket} />
        </div>
      </Container>
    </>
  );
};
