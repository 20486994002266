import React, { useState } from "react";
import { Modal } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useFetch } from "@hyper-fetch/react";

import { Integration } from "types";
import { FailedCounterResponse, getFailedJobsCounter, JobSuppression } from "api";
import { JobModel } from "models";
import { Badge, TabOption, Tabs } from "components";
import { IterableSuppressions } from "./iterable-suppressions/iterable-suppressions";
import { SalesforceSuppressions } from "./salesforce-suppressions/salesforce-suppressions";
import { RemarketySuppressions } from "./remarkety-suppressions/remarkety-suppressions";
import { MarketoSuppressions } from "./marketo-suppressions/marketo-suppressions";
import { GreenArrowSuppressions } from "./green-arrow-suppressions/green-arrow-suppressions";
import { InsiderSuppressions } from "./insider-suppressions/insider-suppressions";
import { BlueshiftSuppressions } from "./blueshift-suppressions/blueshift-suppressions";
import {
  DataExtensionValueUpdate,
  GreenArrowValueUpdate,
  InsiderValueUpdate,
  JobListenerEventsKeys,
  JobSocketInstance,
  MarketoValueUpdate,
  ProjectValueUpdateData,
  RemarketyValueUpdate,
} from "hooks";
import { Blueshift, GreenArrow, Insider, Iterable, Marketo, Remarkety, Salesforce } from "assets";

import styles from "./suppress-details.module.scss";

type SuppressDetailsProps = {
  job: JobModel;
  suppression: JobSuppression | null;
  isOpen: boolean;
  handleClose: () => void;
  socket: JobSocketInstance | null;
};

export const SuppressDetails: React.FC<SuppressDetailsProps> = ({ job, suppression, isOpen, handleClose, socket }) => {
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>("iterable");
  const [failedCounter, setFailedCounter] = useState<FailedCounterResponse>({
    iterable: 0,
    remarkety: 0,
    salesforce: 0,
    marketo: 0,
    greenarrow: 0,
    insider: 0,
    blueshift: 0,
  });

  const { onSuccess } = useFetch(getFailedJobsCounter.setParams({ jobId: job.id, valueId: suppression?.id || "" }), {
    dependencies: [suppression],
    disabled: !suppression,
  });
  onSuccess(({ response }) => {
    setFailedCounter(response);
  });

  const handleIntegrationChange = (option: TabOption<Integration>) => {
    setSelectedIntegration(option.value);
  };

  useDidUpdate(
    () => {
      type UpdateData =
        | ProjectValueUpdateData
        | DataExtensionValueUpdate
        | RemarketyValueUpdate
        | MarketoValueUpdate
        | GreenArrowValueUpdate
        | InsiderValueUpdate;

      const updateFailedCounter =
        (key: keyof FailedCounterResponse) =>
        ({ status }: UpdateData) => {
          if (status === "failed") {
            setFailedCounter((prevState) => ({
              ...prevState,
              [key]: prevState[key] + 1,
            }));
          }
        };

      if (socket) {
        socket.on<JobListenerEventsKeys>("valueProjectUpdate", updateFailedCounter("iterable"));
        socket.on<JobListenerEventsKeys>("valueDataExtensionUpdate", updateFailedCounter("salesforce"));
        socket.on<JobListenerEventsKeys>("valueRemarketyAccountUpdate", updateFailedCounter("remarkety"));
        socket.on<JobListenerEventsKeys>("valueMarketoAccountUpdate", updateFailedCounter("marketo"));
        socket.on<JobListenerEventsKeys>("valueGreenarrowAccountUpdate", updateFailedCounter("greenarrow"));
        socket.on<JobListenerEventsKeys>("valueInsiderAccountUpdate", updateFailedCounter("insider"));
        socket.on<JobListenerEventsKeys>("valueBlueshiftAccountUpdate", updateFailedCounter("blueshift"));

        return () => {
          socket.off<JobListenerEventsKeys>("valueProjectUpdate", updateFailedCounter("iterable"));
          socket.off<JobListenerEventsKeys>("valueDataExtensionUpdate", updateFailedCounter("salesforce"));
          socket.off<JobListenerEventsKeys>("valueRemarketyAccountUpdate", updateFailedCounter("remarkety"));
          socket.off<JobListenerEventsKeys>("valueMarketoAccountUpdate", updateFailedCounter("marketo"));
          socket.off<JobListenerEventsKeys>("valueGreenarrowAccountUpdate", updateFailedCounter("greenarrow"));
          socket.off<JobListenerEventsKeys>("valueBlueshiftAccountUpdate", updateFailedCounter("blueshift"));
        };
      }
    },
    [socket],
    true,
  );

  useDidUpdate(() => {
    setSelectedIntegration("iterable");
  }, [isOpen]);

  const tabOptions: (TabOption<Integration> & { espActive: boolean })[] = [
    {
      value: "iterable",
      label: "Iterable",
      icon: <Iterable />,
      espActive: job?.esps?.iterable || false,
      suffixIcon: failedCounter.iterable ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.iterable}
        </Badge>
      ) : null,
    },
    {
      value: "salesforce",
      label: "Salesforce",
      icon: <Salesforce />,
      espActive: job?.esps?.salesforce || false,
      suffixIcon: failedCounter.salesforce ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.salesforce}
        </Badge>
      ) : null,
    },
    {
      value: "remarkety",
      label: "Remarkety",
      icon: <Remarkety />,
      espActive: job?.esps?.remarkety || false,
      suffixIcon: failedCounter.remarkety ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.remarkety}
        </Badge>
      ) : null,
    },
    {
      value: "marketo",
      label: "Marketo",
      icon: <Marketo />,
      espActive: job?.esps?.marketo || false,
      suffixIcon: failedCounter.marketo ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.marketo}
        </Badge>
      ) : null,
    },
    {
      value: "greenarrow",
      label: "GreenArrow",
      icon: <GreenArrow style={{ width: "15px", flexShrink: 0 }} />,
      espActive: job?.esps?.greenarrow || false,
      suffixIcon: failedCounter.greenarrow ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.greenarrow}
        </Badge>
      ) : null,
    },
    {
      value: "insider",
      label: "Insider",
      icon: <Insider />,
      espActive: job?.esps?.insider || false,
      suffixIcon: failedCounter.insider ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.insider}
        </Badge>
      ) : null,
    },

    {
      value: "blueshift",
      label: "Blueshift",
      icon: <Blueshift />,
      espActive: job?.esps?.blueshift || false,
      suffixIcon: failedCounter.blueshift ? (
        <Badge variant="error" className={styles.badge}>
          {failedCounter.blueshift}
        </Badge>
      ) : null,
    },
  ];

  const activeIntegrationOptions = tabOptions.filter((option) => option.espActive);

  return (
    <Modal isOpen={isOpen} setClose={handleClose} contentClassName={styles.modalContent}>
      <div>
        <div className={styles.header}>
          <span className={styles.label}>{suppression?.value}</span>

          <Tabs
            options={activeIntegrationOptions}
            selected={selectedIntegration}
            onChange={handleIntegrationChange}
            className={styles.tabs}
            buttonClassName={styles.tabButton}
          />
        </div>

        {suppression && (
          <>
            {selectedIntegration === "iterable" && (
              <IterableSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
            {selectedIntegration === "salesforce" && (
              <SalesforceSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
            {selectedIntegration === "remarkety" && (
              <RemarketySuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
            {selectedIntegration === "marketo" && (
              <MarketoSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
            {selectedIntegration === "greenarrow" && (
              <GreenArrowSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
            {selectedIntegration === "insider" && (
              <InsiderSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
            {selectedIntegration === "blueshift" && (
              <BlueshiftSuppressions jobId={job.id} suppressValueId={suppression.id} socket={socket} />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
