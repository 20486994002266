import React, { useState } from "react";
import { Nullable, Pagination, Search, SelectOption, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDebounce } from "@better-hooks/performance";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { blueshiftSuppressionColumns, initialFilters, TABLE_NAME } from "./blueshift-suppressions.constants";
import { SuppressDetailsItem } from "../suppress-details-item/suppress-details-item";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { getInitialStorageFilters } from "utils";
import { getSuppressionsValueBlueshiftAccounts, SuppressionDetail } from "api";
import { BlueshiftValueUpdate, JobListenerEventsKeys, JobSocketInstance } from "hooks";
import { List } from "models";
import { StatusFilter, TableError, TableNoContent, ErrorDrawer } from "components";

import styles from "../suppress-details.module.scss";

interface Props {
  jobId: number;
  suppressValueId: string;
  socket: JobSocketInstance | null;
}

export const BlueshiftSuppressions: React.FC<Props> = ({ jobId, suppressValueId, socket }) => {
  const { debounce } = useDebounce({ delay: 1200 });
  const { query, state, currentState, setValue } = useFilters<BasePaginationQuery & BaseSearchQuery>({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
    isStateBased: true,
  });

  const [status, setStatus] = useState<string>("");
  const [suppressionProjects, setSuppressionProjects] = useState<List<SuppressionDetail> | null>(null);
  const [errorToPreview, setErrorToPreview] = useState<Nullable<string>>(null);

  const { loading, error, refetch, onSuccess } = useFetch(
    getSuppressionsValueBlueshiftAccounts
      .setParams({ jobId, valueId: suppressValueId })
      .setQueryParams({ ...query, ...state, status }),
  );
  onSuccess(({ response }) => setSuppressionProjects(response));

  const pagination = usePagination({
    listPayload: suppressionProjects,
    isStateBased: true,
    initialState: currentState,
  });

  useDidUpdate(
    () => {
      const valueProjectUpdate = ({ blueshiftAccountId, status, valueId }: BlueshiftValueUpdate) => {
        setSuppressionProjects((prevState) => {
          if (!prevState) return null;
          if (valueId !== suppressValueId) return prevState;

          const newData = prevState.data.map((project) => {
            if (project.id === blueshiftAccountId) {
              return { ...project, status };
            }
            return project;
          });

          return { ...prevState, data: newData };
        });
      };

      if (socket) {
        socket.on<JobListenerEventsKeys>("valueBlueshiftAccountUpdate", valueProjectUpdate);

        return () => {
          socket.off<JobListenerEventsKeys>("valueBlueshiftAccountUpdate", valueProjectUpdate);
        };
      }
    },
    [socket],
    true,
  );

  const handleStatusChange = (value: SelectOption<string> | null) => {
    setStatus(value?.value || "");
  };

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.filtersWrapper}>
          <div className={styles.searchWrapper}>
            <Search
              searchValue={state.search}
              setSearch={(value) => {
                debounce(() => setValue("search")(value));
              }}
              width="small"
              onClearClick={() => setValue("search")("")}
              className={styles.search}
            />
          </div>

          <StatusFilter selectedStatus={status} onChange={handleStatusChange} type="esp" />
        </div>
        <div>
          <Table
            entityName={TABLE_NAME}
            columns={blueshiftSuppressionColumns}
            list={suppressionProjects?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            className={styles.table}
            contentClassName={styles.tableContent}
            customNoContent={<TableNoContent />}
            customError={<TableError description={error?.message} />}
            row={(suppressionValue, index) => (
              <SuppressDetailsItem
                value={suppressionValue}
                integration="blueshift"
                index={index}
                onErrorBadgeClick={(error) => setErrorToPreview(error)}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          {...pagination}
          onPageChange={(page, offset) => {
            pagination.onPageChange(page, offset);
            setValue("offset")(offset);
          }}
          onElementsPerPageChange={(value) => {
            pagination.onElementsPerPageChange(value);
            setValue("limit")(value);
          }}
        />
      </div>

      <ErrorDrawer
        isOpen={!!errorToPreview}
        handleClose={() => setErrorToPreview(null)}
        errorToPreview={errorToPreview}
      />
    </div>
  );
};
