import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import {
  ExportJobsListenerHandlers,
  ExportJobsListenerEventsKeys,
  ExportJobsSocketInstance,
} from "./use-export-jobs-socket.types";

const SOCKET_EXPORT_JOBS_NAMESPACE = "/md5s-export";

export const useExportJobsSocket = () => {
  const [socket, setSocket] = useState<ExportJobsSocketInstance | null>(null);
  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const listenerHandlers: Partial<ExportJobsListenerHandlers> = {
    connect: () => {
      setConnected(true);
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<Partial<ExportJobsListenerHandlers>>) =>
    <E extends ExportJobsListenerEventsKeys>(event: E) => {
      socketInstance.on<ExportJobsListenerEventsKeys>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<ExportJobsListenerEventsKeys>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<ExportJobsListenerHandlers>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    const socketInstance: Socket<ExportJobsListenerHandlers> = io(
      appEnvironment.apiUrl + SOCKET_EXPORT_JOBS_NAMESPACE,
      {
        autoConnect: false,
        extraHeaders: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    const unmountListeners = mountListeners(socketInstance);
    socketInstance.connect();

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      unmountListeners();
    };
  });

  return { socket, isConnected };
};
